import gql from 'graphql-tag'


export const submit3DSecureFormResult = gql`
    mutation submit3DSecureFormResult(
      $orderId: String!,
      $status: String!,
      $payId: String!,
      $ncError: String!,
      $shaSign: String!,
    ) {
      submit3DSecureFormResult(
        orderId: $orderId,
        status: $status,
        payId: $payId,
        ncError: $ncError,
        shaSign: $shaSign,
      ) {
        id
      }
}
`

